import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  InfoCard,
  Plano,
} from 'src/app/dashboard/dashboard-home/model/dashboard';
import { environment } from 'src/environments/environment';
import { RegistroRequest } from '../models/registro';

@Injectable({
  providedIn: 'root',
})
export class RegistroService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiUrl}/registros`;
  }

  create(register: Partial<RegistroRequest>): Observable<void> {
    return this.http.post<void>(`${this.url}`, register);
  }

  purchasePlan(infoCard: Partial<InfoCard>): Observable<void> {
    return this.http.put<void>(`${this.url}/renovar`, infoCard);
  }

  getPlans(): Observable<Plano[]> {
    return this.http.get<Plano[]>(`${this.url}/pacotes`);
  }
}
