<app-container-manager-login>
  <div class="p-d-flex w-100" *ngIf="msg">
    <p-message severity="error" [text]="msg"></p-message>
  </div>
  <form
    class="signin-form p-fluid"
    autocomplete="off"
    [formGroup]="form"
    (submit)="login()"
  >
    <div class="form-group p-mb-4">
      <label class="label" for="name">Usuário</label>
      <input
        type="email"
        pInputText
        placeholder="email"
        name="usuario"
        formControlName="usuario"
        class="form-control"
      />
    </div>
    <div class="form-group p-mb-3">
      <label class="label" for="password">Senha</label>
      <input
        type="password"
        pInputText
        formControlName="senha"
        name="senha"
        class="form-control"
        placeholder="Senha"
        [required]="true"
      />
    </div>

    <div
      class="form-group p-text-center"
      style="font-size: 16px; line-height: 24px"
    >
      <a
        [routerLink]="['/solicitar-reset-senha']"
        style="
          color: #2c3f50 !important;
          text-decoration-line: underline !important;
          font-style: italic !important;
          font-weight: 700 !important;
        "
      >
        Esqueci minha senha
      </a>
    </div>
    <div
      class="form-group"
      style="font-size: 16px; line-height: 24px"
      *ngIf="controlaAssinatura && !loadding"
    >
      Ainda não tem uma conta?
      <a
        [routerLink]="['/novo-cadastro']"
        style="
          color: #2c3f50 !important;
          text-decoration-line: underline !important;
          font-style: italic !important;
          font-weight: 700 !important;
        "
      >
        Cadastre-se
      </a>
    </div>

    <div class="form-group p-text-center">
      <button
        pButton
        style="height: 48px"
        type="submit"
        [disabled]="form.invalid"
        *ngIf="!loadding"
        label="Entrar"
      ></button>
      <p-progressSpinner
        strokeWidth="8"
        [style]="{ width: '50px', height: '50px' }"
        *ngIf="loadding"
      >
      </p-progressSpinner>
    </div>
  </form>
</app-container-manager-login>
