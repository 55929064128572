import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/seguranca/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-notificacao-vencimento',
  templateUrl: './layout-notificacao-vencimento.component.html',
  styleUrl: './layout-notificacao-vencimento.component.scss',
})
export class LayoutNotificacaoVencimentoComponent implements OnInit {
  controlaAssinatura = environment.controlaAssinatura;

  mensagem?: string = '';

  constructor(private authService: AuthService) {}
  ngOnInit(): void {
    this.mensagem = this.authService.jwtPayload?.mensagemBloqueio;
  }
}
