<div class="publicit">
  <div class="p-d-flex p-jc-center p-ai-center">
    <div class="p-pl-3 p-pr-3">
      <div class="p-d-flex p-jc-center">
        <div class="content-list">
          <h1 class="bold text-white text-24 text-md-38 p-mb-4">
            Sua <span style="color: #fff"> Gestão</span> pode ser <br />
            simples como um <span style="color: #fff">Click!</span>
          </h1>
          <ul>
            <li
              class="text-white text-16 text-md-18 p-mb-3 p-d-flex p-ai-center"
            >
              <app-novo-cadastro-publicidade-check></app-novo-cadastro-publicidade-check>
              Não é preciso cadastrar o cartão de crédito.
            </li>
            <li
              class="text-white text-16 text-md-18 p-mb-3 p-d-flex p-ai-center"
            >
              <app-novo-cadastro-publicidade-check></app-novo-cadastro-publicidade-check>
              Acesso liberado a todos os módulos
            </li>
            <li
              class="text-white text-16 text-md-18 p-mb-3 p-d-flex p-ai-center"
            >
              <app-novo-cadastro-publicidade-check></app-novo-cadastro-publicidade-check>
              Cancele a qualquer momento
            </li>
          </ul>
        </div>
      </div>
      <picture class="p-pt-0 p-pr-5 pl-5">
        <source
          media="(min-width: 700px)"
          srcset="
            https://gestaoclick.com.br/wp-content/uploads/gestao-simples-como-click-gck.webp
          "
          sizes="100vw"
        />
        <img
          fetchpriority="high"
          src="https://gestaoclick.com.br/wp-content/uploads/gck-logo-simples-tablet.webp"
          alt="Sistema GestãoClick - Experimente grátis"
          class="sistema-gck"
          width="550"
        />
      </picture>
    </div>
  </div>
</div>
