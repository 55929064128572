<div class="layout-wrapper">
  <div
    #layoutContainer
    class="layout-container"
    [ngClass]="{
      'menu-layout-static': !isOverlay(),
      'menu-layout-overlay': isOverlay(),
      'layout-menu-overlay-active': overlayMenuActive,
      'menu-layout-horizontal': isHorizontal(),
      'menu-layout-slim': isSlim(),
      'layout-menu-static-inactive': staticMenuDesktopInactive,
      'layout-menu-static-active': staticMenuMobileActive
    }"
  >
    <app-toolbar></app-toolbar>
    <div
      class="layout-menu"
      [ngClass]="{ 'layout-menu-dark': darkMenu }"
      (click)="onMenuClick($event)"
    >
      <p-scrollPanel #scrollPanel [style]="{ height: '100%' }">
        <app-profile
          *ngIf="profileMode === 'inline' && !isHorizontal()"
        ></app-profile>
        <app-menu [reset]="resetMenu"></app-menu>
      </p-scrollPanel>
    </div>

    <div class="layout-main">
      <app-breadcrumb></app-breadcrumb>
      <app-layout-notificacao-vencimento></app-layout-notificacao-vencimento>
      <div class="layout-content">
        <router-outlet> </router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>

<p-toast></p-toast>

<p-confirmDialog
  [style]="{ width: '280px' }"
  header="Confirmação"
  aria-label="dialog-confimar"
  icon="pi pi-question-circle"
  #confirmacao
>
  <p-footer>
    <div class="p-d-flex p-justify-around">
      <button
        type="button"
        aria-label="btn-confirmar-sim"
        pButton
        icon="pi pi-confirm"
        label="Sim"
        (click)="confirmacao.accept()"
        class="p-button-text p-button-plain"
      ></button>
      <button
        type="button"
        aria-label="btn-confirmar-nao"
        pButton
        icon="fa fa-times"
        label="Não"
        (click)="confirmacao.reject()"
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>
<app-loading></app-loading>
