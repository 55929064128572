import { Component } from '@angular/core';
import { DashboardPlanosComponent } from 'src/app/dashboard/dashboard-home/components/dashboard-planos/dashboard-planos.component';

@Component({
  selector: 'app-assinatura-page',
  standalone: true,
  imports: [DashboardPlanosComponent],
  templateUrl: './assinatura-page.component.html',
  styleUrl: './assinatura-page.component.scss',
})
export class AssinaturaPageComponent {}
