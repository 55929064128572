import { Component, OnInit } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { RegistroService } from 'src/app/view/novo-cadastro/services/registro.service';
import { Plano } from '../../model/dashboard';
import { DashboardCheckoutAssinaturaComponent } from '../dashboard-checkout-assinatura/dashboard-checkout-assinatura.component';
import { DashboardPlanoComponent } from '../dashboard-plano/dashboard-plano.component';

@Component({
  selector: 'app-dashboard-planos',
  standalone: true,
  imports: [
    SharedModule,
    DashboardPlanoComponent,
    DashboardCheckoutAssinaturaComponent,
  ],
  templateUrl: './dashboard-planos.component.html',
  styleUrl: './dashboard-planos.component.scss',
})
export class DashboardPlanosComponent implements OnInit {
  display = false;

  planos: Plano[] = [];
  planosSelecionados: Plano[] = [];
  idplan = -1;

  activeButtonYearPlan = false;
  activeButtonMotherPlan = true;
  activeButtonSemesterPlan = false;

  constructor(private service: RegistroService) {}
  ngOnInit(): void {
    this.service.getPlans().subscribe({
      next: (response) => {
        this.planos = response;
        this.onSelectYearPlan();
      },
    });
  }

  onSelectYearPlan() {
    this.activeButtonYearPlan = true;
    this.activeButtonMotherPlan = false;
    this.activeButtonSemesterPlan = false;
    this.planosSelecionados = this.planos.filter((p) => p.tipo === 'M');
  }

  onSelectMotherPlan() {
    this.activeButtonYearPlan = false;
    this.activeButtonMotherPlan = true;
    this.activeButtonSemesterPlan = false;
    this.planosSelecionados = this.planos.filter((p) => p.tipo === 'A');
  }

  onSelectSemesterPlan() {
    this.activeButtonYearPlan = false;
    this.activeButtonMotherPlan = false;
    this.activeButtonSemesterPlan = true;
    this.planosSelecionados = this.planos.filter((p) => p.tipo === 'S');
  }

  onPurchasePlan(plan: Plano) {
    this.idplan = plan.id;
    this.display = true;
  }
}
