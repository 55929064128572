import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { RegistroService } from '../../services/registro.service';

@Component({
  selector: 'app-novo-cadastro-form',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './novo-cadastro-form.component.html',
  styleUrl: './novo-cadastro-form.component.scss',
})
export class NovoCadastroFormComponent {
  loadding = false;

  form = this.createForm();

  constructor(
    private service: RegistroService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  onSubmit() {
    if (this.form.invalid) return;

    this.loadding = true;
    var request = this.form.value;

    this.service
      .create(request)
      .subscribe({
        next: () => {
          this.router.navigate(['/login']);
        },
      })
      .add(() => (this.loadding = false));
  }

  createForm() {
    return this.formBuilder.group({
      nome: new FormControl<string>('', {
        validators: [Validators.required, Validators.minLength(8)],
        nonNullable: true,
      }),
      email: new FormControl<string>('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
      }),
      senha: new FormControl<string>('', {
        validators: [Validators.required, Validators.minLength(8)],
        nonNullable: true,
      }),
      cnpj: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      fone: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }
}
