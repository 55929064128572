import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { Plano } from '../../model/dashboard';

@Component({
  selector: 'app-dashboard-plano',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dashboard-plano.component.html',
  styleUrl: './dashboard-plano.component.scss',
})
export class DashboardPlanoComponent {
  @Input() plano?: Plano;

  @Output() eventPurchasePlan = new EventEmitter();

  onSelectPlan() {
    this.eventPurchasePlan.emit(this.plano);
  }
}
