<div class="p-lg-4 p-md-6 p-sm-12">
  <div class="container-plan p-d-flex p-flex-column">
    <div class="plan-header p-text-40">{{ plano?.nome }}</div>
    <div class="plan-body p-d-flex p-flex-column">
      <div class="p-text-14 text-blue p-text-center p-pr-4 p-pl-4">
        {{ plano?.descricao }}
      </div>
      <div class="p-p-4">
        <div class="plan-price p-pb-3 yearly discount">
          <div class="savings">Economize R$792,00</div>
          <div class="price pt-2">
            <strong>
              R$<span class="text-56 line-height-100">{{
                plano?.valor | number: '1.2-2'
              }}</span>
            </strong>
            <span class="text-12">/mês</span>
          </div>
        </div>
        <a
          id="btn-plano-bronze"
          class="select-plan btn w-100 py-3 btn-green p-p-3"
          (click)="onSelectPlan()"
          >Adiquirir</a
        >
      </div>
    </div>

    <div class="plan-emphasis p-p-4">
      <ul class="no-bullets p-text-left">
        <li class="p-mb-2 p-d-flex p-ai-center">
          <img
            width="23"
            height="23"
            alt="usuários"
            class="p-pr-2 ls-is-cached lazyloaded"
            src="/assets/images/usuario-gck.svg"
          />
          usuários ilimitados
        </li>
        <li class="p-mb-2 p-d-flex p-ai-center">
          <img
            width="23"
            height="23"
            alt="empresa"
            class="pr-2 ls-is-cached lazyloaded"
            src="/assets/images/empresa-gck.svg"
          />
          1 empresa
        </li>
        <li class="p-mb-2 p-d-flex p-ai-center">
          <img
            width="23"
            height="23"
            alt="espaço"
            class="p-pr-2 ls-is-cached lazyloaded"
            src="/assets/images/espaco-gck.svg"
          />Espaço ilimitado
        </li>
      </ul>
    </div>

    <div class="plan-detail p-pb-2 p-pl-4 p-pr-4 p-pb-2">
      <ul class="p-text-left">
        <li
          class="p-mb-2 p-d-flex p-ai-start active"
          *ngFor="let module of plano?.modulos"
        >
          <img
            width="16"
            height="16"
            alt="Incluso no plano"
            class="p-pr-2"
            src="/assets/images/check-gck.svg"
          />
          {{ module.nome }}
        </li>

        <li class="p-mb-2 p-d-flex p-ai-start">
          <img
            width="16"
            height="16"
            decoding="async"
            data-src="/assets/images/check-gck.svg"
            alt="Incluso no plano"
            class="p-pr-2"
            src="/assets/images/check-gck.svg"
          />Certificado digital
        </li>
        <li class="p-mb-2 p-d-flex p-ai-start">
          <img
            width="16"
            height="16"
            decoding="async"
            data-src="/assets/images/check-gck.svg"
            alt="Incluso no plano"
            class="p-pr-2"
            src="/assets/images/check-gck.svg"
          />Suporte gratuito
        </li>
        <li class="p-mb-2 p-d-flex p-ai-start">
          <img
            width="16"
            height="16"
            decoding="async"
            alt="Incluso no plano"
            class="p-pr-2"
            src="/assets/images/check-gck.svg"
          />Sem taxa de instalação
        </li>
      </ul>
    </div>
  </div>
</div>
