<a
  href="https://wa.me/5579999985542?text=Oi! Gostaria de conhecer o DF-e."
  target="_blank"
  title="Contate-nos pelo WhatsApp"
>
  <div
    class="joinchat joinchat--left joinchat--btn joinchat--show joinchat--tooltip"
    data-settings='{"telephone":"553125330330","mobile_only":false,"button_delay":2,"whatsapp_web":false,"qr":false,"message_views":2,"message_delay":10,"message_badge":false,"message_send":"Olá, tenho interesse no sistema GestãoClick!","message_hash":""}'
  >
    <div class="joinchat__button">
      <div class="joinchat__button__open"></div>
    </div>
  </div>
</a>
