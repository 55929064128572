import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardNumber',
  standalone: true,
})
export class CardNumberPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    const mask = '•••• •••• •••• ••••';
    if (!value) return mask;

    const masked = value.padEnd(mask.length - 3, '•');
    const chunks = masked.match(/.{1,4}/g);

    return chunks?.join(' ');
  }
}
