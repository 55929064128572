import { Component } from '@angular/core';
import { NovoCadastroPublicidadeCheckComponent } from '../novo-cadastro-publicidade-check/novo-cadastro-publicidade-check.component';

@Component({
  selector: 'app-novo-cadastro-publicidade',
  standalone: true,
  imports: [NovoCadastroPublicidadeCheckComponent],
  templateUrl: './novo-cadastro-publicidade.component.html',
  styleUrl: './novo-cadastro-publicidade.component.scss',
})
export class NovoCadastroPublicidadeComponent {}
