import { Component } from '@angular/core';

@Component({
  selector: 'app-novo-cadastro-publicidade-check',
  standalone: true,
  imports: [],
  templateUrl: './novo-cadastro-publicidade-check.component.html',
  styleUrl: './novo-cadastro-publicidade-check.component.scss'
})
export class NovoCadastroPublicidadeCheckComponent {

}
