import {
  AbstractControl,
  FormControl,
  FormControlName,
  FormGroup,
} from '@angular/forms';
import * as moment from 'moment';
import { Grade } from 'src/app/view/comercial/shared/model/grade.model';
import { ProdutoPrecoVenda } from 'src/app/view/comercial/shared/model/produto-preco-venda.model';

export function calcularValorPercentual(
  valor: number,
  percentual: number,
  arredontamento?: number
): number {
  const valorSobrePercentual = (valor * percentual) / 100;

  return arredontamento ? round(valorSobrePercentual) : valorSobrePercentual;
}

export function round(value: number): number {
  return Math.round(value * 100) / 100;
}

export function addDias(dias: number, data: Date): Date {
  return moment(data).add(dias, 'days').toDate();
}

export function getPreco(produto: ProdutoPrecoVenda) {
  return produto.precoPromocional
    ? produto.precoPromocional
    : produto.valorVenda;
}

export function getPrecoAtacado(
  quantidade: number,
  produto: ProdutoPrecoVenda
) {
  if (produto.precoPromocional) {
    return produto.precoPromocional;
  } else if (
    produto.valorAtacado &&
    produto.quantidadeAtacado &&
    quantidade >= produto.quantidadeAtacado
  ) {
    return produto.valorAtacado;
  } else {
    return produto.valorVenda;
  }
}

export function criarListaColores(grades: Grade[]) {
  return grades
    .sort()
    .reduce((init: Grade[], current) => {
      if (
        init.length === 0 ||
        !init.some((c) => c.cor.id === current.cor.id!)
      ) {
        init.push(current);
      }
      return init;
    }, [])
    .map((c) => ({ label: c.cor.nome!, value: c.cor.id! }));
}

export function calcularFator(valor1: number, valor2: number) {
  return Number((((valor1 - valor2) / valor1) * 100).toFixed(2));
}

export function calcularDisconto(
  disconto = 0,
  valorSubtotal = 0,
  isPercentageDiscount = true
) {
  let valorDesconto = 0;
  let taxaDesconto = 0;

  if (!isPercentageDiscount) {
    valorDesconto = disconto;

    taxaDesconto = disconto / valorSubtotal;
  } else {
    taxaDesconto = disconto / 100;

    valorDesconto = valorSubtotal * taxaDesconto;
  }

  return { taxaDesconto, valorDesconto };
}

export function arredondarValor(valor: number) {
  return Number(valor.toFixed(2));
}

export function randomId() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);
}

export const hasRequiredField = (
  abstractControl: AbstractControl | FormControlName
): boolean => {
  if (!abstractControl) {
    return false;
  }

  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator['required']) {
      return true;
    }
  }

  return false;
};

export function converterStringsParaDatas(data: string | Date): Date {
  return moment(data).toDate();
}

export function dateToString(
  data: Date | string,
  formato = 'YYYY-MM-DD'
): string {
  return moment(data).format(formato);
}

export function stringDateFormat(data: string, formato = 'YYYY-MM-DD'): string {
  return data ? moment(data).format(formato) : '';
}

export function dateToUtc(data: Date | string): Date {
  return moment(data + 'Z')
    .utc()
    .local()
    .toDate();
}

export function toRound(value: number) {
  return Math.round(value * 100) / 100;
}

export function unmaskCpfCnpj(cpfCnpj: string): string {
  return cpfCnpj.replace(/[^0-9]/g, '');
}

export function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export function printReport(
  elemento: HTMLElement,
  openNewWindows = false,
  showPrint = false,
  css = '.container{width: 385px;height: auto;background: #FFFFFF;margin: 0 auto;padding: 25px;}'
) {
  const mywindow = openNewWindows
    ? window.open('', '_blank')
    : window.open('', '_self');
  let globalCss =
    '* {margin: 0;padding: 0;outline: 0;box-sizing: border-box;} ';
  globalCss += 'html, body{height: 100%;} ';
  globalCss +=
    'body {font: 14px Arial, sans-serif;-webkit-font-smoothing: antialiased !important;} ';
  globalCss +=
    '@page {margin: 0;padding: 0;outline: 0;box-sizing: border-box;} ';
  globalCss +=
    '.text-center{text-align: center} .text-right{text-align: right}';

  if (mywindow) {
    mywindow.document.write(
      '<html><head> <title>' + document.title + '</title>'
    );
    mywindow.document.write('<style type="text/css">');
    mywindow.document.write(globalCss);
    mywindow.document.write(css);
    mywindow.document.write('</style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write('<div class="container"> ');
    mywindow.document.write(elemento.innerHTML);
    mywindow.document.write('</div> ');
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    if (showPrint) {
      mywindow.print();
      setTimeout(() => {
        mywindow.close();
      }, 0);
    }
  }
}

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any>
    ? FormGroup<ControlsOf<T[K]>>
    : FormControl<T[K]>;
};

export function numeroParaExtenso(valor: number): string {
  const unidades = [
    '',
    'um',
    'dois',
    'três',
    'quatro',
    'cinco',
    'seis',
    'sete',
    'oito',
    'nove',
  ];
  const especiais = [
    '',
    'onze',
    'doze',
    'treze',
    'quatorze',
    'quinze',
    'dezesseis',
    'dezessete',
    'dezoito',
    'dezenove',
  ];
  const dezenas = [
    '',
    'dez',
    'vinte',
    'trinta',
    'quarenta',
    'cinquenta',
    'sessenta',
    'setenta',
    'oitenta',
    'noventa',
  ];
  const centenas = [
    '',
    'cento',
    'duzentos',
    'trezentos',
    'quatrocentos',
    'quinhentos',
    'seiscentos',
    'setecentos',
    'oitocentos',
    'novecentos',
  ];
  const milhar = 'mil';

  // Quebrar o valor em reais e centavos
  const partes = valor.toFixed(2).split('.');
  const reais = parseInt(partes[0], 10);
  const centavos = parseInt(partes[1], 10);

  // Função auxiliar para converter valores de 1 a 999
  function converteParte(valor: number): string {
    if (valor === 0) return '';
    if (valor === 100) return 'cem';
    let resultado = '';
    const c = Math.floor(valor / 100);
    const d = Math.floor((valor % 100) / 10);
    const u = valor % 10;

    if (c > 0) resultado += centenas[c];
    if (d > 1) {
      if (resultado) resultado += ' e ';
      resultado += dezenas[d];
    }
    if (d === 1 && u > 0) {
      if (resultado) resultado += ' e ';
      resultado += especiais[u];
    } else if (u > 0) {
      if (resultado) resultado += ' e ';
      resultado += unidades[u];
    }

    return resultado;
  }

  // Converte reais
  let extensoReais = '';
  if (reais >= 1000) {
    const milhares = Math.floor(reais / 1000);
    extensoReais += `${converteParte(milhares)} ${milhar}`;
    const resto = reais % 1000;
    if (resto > 0) extensoReais += ` e ${converteParte(resto)}`;
  } else {
    extensoReais += converteParte(reais);
  }
  extensoReais += reais === 1 ? ' real' : ' reais';

  // Converte centavos
  let extensoCentavos = '';
  if (centavos > 0) {
    extensoCentavos = converteParte(centavos);
    extensoCentavos += centavos === 1 ? ' centavo' : ' centavos';
  }

  // Combina reais e centavos
  if (extensoReais && extensoCentavos) {
    return `${extensoReais} e ${extensoCentavos}`;
  } else if (extensoReais) {
    return extensoReais;
  } else {
    return extensoCentavos;
  }
}
