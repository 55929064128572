import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/seguranca/auth.service';
import { environment } from 'src/environments/environment';
import { LayoutComponent } from '../layout/layout.component';
import { MENU } from './menu';

@Component({
  selector: 'app-menu',
  template: `
    <ul
      app-submenu
      [item]="model"
      [root]="true"
      class="ultima-menu ultima-main-menu clearfix"
      [reset]="reset"
      [visible]="true"
      [parentActive]="true"
    ></ul>
  `,
})
export class MenuComponent implements OnInit {
  @Input() reset: boolean = false;

  model: any[] = [];

  controlaAssinatura = environment.controlaAssinatura;

  constructor(public app: LayoutComponent, private auth: AuthService) {}

  ngOnInit() {
    this.model =
      this.controlaAssinatura && this.auth.havePermission('ROLE_INADIPLENTE')
        ? [{ label: 'Dashboard', icon: 'pi pi-chart-line', routerLink: ['/'] }]
        : MENU;
  }

  changeTheme(theme: any) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('theme-css')
    );
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('layout-css')
    );

    themeLink.href = 'assets/theme/theme-' + theme + '.css';
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
  }
}

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-submenu]',
  /* eslint-enable @angular-eslint/component-selector */
  templateUrl: './submenu.component.html',
  animations: [
    trigger('children', [
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class SubMenuComponent {
  @Input() item?: any;

  @Input() root: boolean = false;

  @Input() visible: boolean = false;

  _reset: boolean = false;

  _parentActive: boolean = false;

  activeIndex?: number;

  constructor(public app: LayoutComponent) {}

  itemClick(event: Event, item: MenuItem, index: number): void {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index ? undefined : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.app.layoutMenuScrollerViewChild?.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (
      this.root &&
      this.app.menuHoverActive &&
      (this.app.isHorizontal() || this.app.isSlim()) &&
      !this.app.isMobile() &&
      !this.app.isTablet()
    ) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = undefined;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = undefined;
    }
  }
}
