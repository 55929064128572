<img
  width="24px"
  height="24px"
  alt="Sem cartão de credito"
  class="p-mr-2 lazyloaded"
  src="assets/images/checklist-1.svg"
  style="
    --smush-placeholder-width: 24px;
    --smush-placeholder-aspect-ratio: 24/24;
  "
/>
