import { Component } from '@angular/core';

@Component({
  selector: 'app-novo-cadastro-suport',
  standalone: true,
  imports: [],
  templateUrl: './novo-cadastro-suport.component.html',
  styleUrl: './novo-cadastro-suport.component.scss'
})
export class NovoCadastroSuportComponent {

}
