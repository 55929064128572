<div class="alerta">
  <strong> {{ mensagem }}</strong>
  <a
    id="btn-assinar"
    pButton
    label="Assine Agora"
    data-cy="btn-novo"
    routerLink="/assinatura"
    *ngIf="controlaAssinatura"
  ></a>
</div>
