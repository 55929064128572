import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loadding = false;

  msg?: string;

  form = this.criarForm();

  controlaAssinatura = environment.controlaAssinatura;

  constructor(
    private auth: AuthService,
    private router: Router,
    private globalService: GlobalDataService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    if (!this.auth.isAccessTokenInvalido()) {
      this.router.navigate(['/dashboard']);
    }
  }

  login() {
    const { usuario, senha } = this.form.value;

    this.loadding = true;
    this.auth
      .login(usuario!, senha!)
      .then(() => {
        this.globalService.clearEmpresa();
        const redirect: string = this.auth.redirectUrl || '/dashboard';
        this.router.navigate([redirect]);

        this.loadding = false;
      })
      .catch((erro) => {
        this.loadding = false;
        this.msg =
          erro.error?.userMessage ??
          'Erro ao processar serviço remoto. Tente novamente.';
      });
  }

  private criarForm() {
    return this.fb.group({
      usuario: new FormControl<string>('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
      }),
      senha: new FormControl<string>('', {
        validators: [Validators.required, Validators.min(5)],
        nonNullable: true,
      }),
    });
  }
}
